<template>
  <div class="website-config">
    <DgPanel :header="$t('dg_suite.consent_management.configuration.title')">
      <p v-if="isNewWebsite && !areMoreWebsitesPossible">
        {{ $t("dg_suite.consent_management.overview.maximum_reached") }}
      </p>
      <form class="website-config__website" @submit.prevent="submit" v-else-if="isFormShown">
        <div class="website-config__header">
          1. {{ $t("dg_suite.consent_management.configuration.website_information") }}
        </div>
        <div class="website-config__body">
          <div class="row">
            <div class="col-12">
              <DgInput
                :attrs="{
                  value: website.url,
                  required: true,
                  placeholder: $t('dg_suite.consent_management.configuration.url.placeholder'),
                }"
                :label="$t('dg_suite.consent_management.configuration.url.label')"
                :error="errors.url"
                @input="onURLChanged($event)"
              />
            </div>
          </div>
        </div>

        <div class="website-config__header">
          2. {{ $t("dg_suite.consent_management.configuration.terms_and_conditions.heading") }}
        </div>
        <div class="website-config__body">
          <div class="row">
            <div class="col-12">
              <p>
                <a :href="termsAndConditionsURL" target="_blank">
                  <img src="../../images/pdf.svg" />
                  {{ $t("dg_suite.consent_management.configuration.terms_and_conditions.link_text") }}
                </a>
              </p>
              <DgCheckbox
                v-if="!website.id"
                :value="website.terms_and_conditions"
                :label="$t('dg_suite.consent_management.configuration.terms_and_conditions.accept')"
                @input="onTermsAndConditionsAccepted($event)"
              />
            </div>
          </div>
        </div>

        <DgButton :disabled="isButtonDisabled" nativeType="submit">{{
          $t("dg_suite.consent_management.configuration.save")
        }}</DgButton>
      </form>
      <p v-if="backendError">{{ backendError }}</p>
      <DgLoadingIndicator
        :is-loading="isLoading || isSaving"
        :label="$t(`dg_suite.consent_management.configuration.${isSaving ? 'saving' : 'loading'}_label`)"
      />
    </DgPanel>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Structural, Basic } from "@/dgui-customer-components/";

import sharedFunctions from "../../mixins/shared-functions";
import refreshCurrentWebsite from "../../mixins/refresh-current-website";

export default {
  name: "CookieManagerConfiguration",
  mixins: [sharedFunctions, refreshCurrentWebsite],
  components: {
    DgPanel: Structural.DgPanel,
    DgLoadingIndicator: Basic.DgLoadingIndicator,
  },
  data() {
    return {
      isLoading: !this.currentWebsite,
      isSaving: false,
      backendError: null,
      wasFormChanged: false,
      websiteDefaultAttributes: {
        terms_and_conditions: false,
      },
      website: {},
      errors: {},
      validationRules: {
        url: url => this.isValidURL(url),
        terms_and_conditions: terms_and_conditions => terms_and_conditions,
      },
    };
  },
  computed: {
    ...mapState({
      currentWebsite: state => state.dg_suite.currentWebsite || {},
      websites: state => state.dg_suite.websites || {},
    }),
    ...mapGetters(["serverConfig"]),
    termsAndConditionsURL() {
      // TODO: Include this in the backend data instead of having it in the translations.
      const filename = this.$t("dg_suite.consent_management.configuration.terms_and_conditions.filename");
      return `${this.serverConfig.api1}/dg_suite/cookie_manager/${filename}`;
    },
    isFormShown() {
      return !this.isSaving && !this.isLoading && !this.backendError;
    },
    isNewWebsite() {
      return this.$route.params.website_id === "new";
    },
    isButtonDisabled() {
      return !(this.wasFormChanged && this.isValid);
    },
    isValid() {
      return !Object.keys(this.errors).some(key => this.errors[key]);
    },
    websiteData() {
      return { id: this.website.id, url: this.website.url };
    },
  },
  methods: {
    ...mapActions(["fetchWebsite", "fetchWebsites", "saveWebsite"]),
    isValidURL(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        if (e instanceof ReferenceError && e.message.includes("URL")) {
          // taken and adapted from https://stackoverflow.com/a/3809435
          return url.match(
            /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          );
        } else {
          return !(e instanceof TypeError && e.message.includes("URL"));
        }
      }
    },
    onURLChanged(event) {
      this.setField("url", event.target.value);
    },
    onTermsAndConditionsAccepted(hasAcceptedTermsAndConditions) {
      this.setField("terms_and_conditions", hasAcceptedTermsAndConditions);
    },
    setField(field, value) {
      this.wasFormChanged = true;
      this.$set(this.website, field, value);
      this.validate();
    },
    validate() {
      Object.keys(this.validationRules).forEach(field => {
        this.$set(this.errors, field, !this.validationRules[field](this.website[field]));
      });
    },
    applyWebsiteAttributeDefaults() {
      this.website = { ...this.websiteDefaultAttributes, ...this.website };
    },
    async doFetchWebsite() {
      this.isLoading = true;

      try {
        await this.fetchWebsites();

        if (!this.isNewWebsite) {
          await this.fetchWebsite(this.$route.params.website_id);
        } else {
          this.$store.commit("setCurrentWebsiteById", null);
        }

        this.$set(this, "website", { ...this.currentWebsite });
        this.applyWebsiteAttributeDefaults();
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      this.validate();

      if (!this.isValid) {
        return;
      }

      this.isSaving = true;

      try {
        const websiteId = await this.saveWebsite(this.websiteData);
        this.wasFormChanged = false;
        this.$router.push({
          name: "CookieManagerAccess",
          params: {
            wasProvisioned: websiteId,
            website_id: websiteId,
          },
        });
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.isSaving = false;
      }
    },
  },
  watch: {
    website(newValue) {
      if (newValue.id) {
        this.onTermsAndConditionsAccepted(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.website-config {
  .website-config__website {
    position: relative;
    padding-bottom: 10px;
    padding-top: 9px;
  }

  .website-config__header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #9aa7b0;
    padding-bottom: 14px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;

    &.sub {
      font-size: 14px;
      color: #0b344b;
      border: none;
      margin-top: 19px;
      margin-bottom: 5px;
      padding-bottom: 0;
    }
  }
  .website-config__body {
    padding-bottom: 30px;

    .row + .row {
      margin-top: 20px;
    }
  }

  a > img {
    width: 24px;
    margin-right: 8px;
  }
}
</style>
